.root {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  .wrapper {
    text-align: center;
    .title {
      margin-top: 20px;
      & > h1 {
        font-size: 1.5rem;
      }
    }
    .description {
      margin-top: 20px;
      & > h2 {
        font-size: 1.5rem;
        font-weight: 100;
      }
    }
    .key {
      margin: 60px 5px;
      p {
        margin: 0 auto;
        text-align: center;
        font-size: 1.2rem;
        background-color: #EDEDED;
        padding: 5px 0;
        max-width: 240px;
        width: 100%;
        border: solid 1px #D6D6D6;
      }
      &_button {
        margin-top: 20px;
        background-color: #5D90E2;
        color: #fff;
        padding: 5px 0;
        max-width: 160px;
        width: 100%;
        border-radius: 10px;
      }
    }
    .line{
      margin: 60px 10px;
      & > p {
        text-align: left;
        margin: 10px auto 0;
      }
      &_button {
        margin-top: 40px;
        background-color: #FFD72E;
        padding: 10px 0;
        max-width: 280px;
        width: 100%;
        font-size: 1.4rem;
        font-weight: bold;
        border-radius: 10px;
      }
    }
  }
  footer {
    ul {
      margin: 40px 0;
      padding-left: 30px;
      list-style-type: disc;
      li {
        margin-top: 5px;
        a {
          color: #000;
          text-decoration: none;
          &:hover {
            color: #5D90E2;
          }
        }
      }
    }
    small {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      & > p {
        padding: 5px;
      }
    }
  }

  button {
    text-wrap: nowrap;
  }
}